
import { defineComponent, ref, onMounted, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      username: "",
      password: "",
      displayLogin : false,
    });

    //let username = "";
    //let password = "";

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email("L'identifiant doit être un email valide")
        .required("L'identifiant est obligatoire")
        .label("username"),
      password: Yup.string()
        .required("Le mot de passe est obligatoire")
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {

            if (router.currentRoute.value.params.loginpassword) {
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                router.push({ name: stringRec });
              } else {
                router.push({ name: "stillboard" });  //stillboard
              }
              return true;
            }
            console.log(store.getters.currentUser);
            Swal.fire({
              title: "Connexion établie !",
              text:
                "Bienvenue " +
                store.getters.currentUser.con_prenom +
                " " +
                store.getters.currentUser.con_nom +
                " sur votre board StillOnline !",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Accèder à StillOnline",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                router.push({ name: stringRec });
              } else {
                if (store.getters.currentUser.con_redirect_auto) {
                  router.push({ name: store.getters.currentUser.con_redirect_auto });  //stillboard 
                }else {
                  router.push({ name: "stillboard" });  //stillboard 
                }
                

              }

            });


          })
          .catch(() => {
            Swal.fire({
              text: "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Retourner sur la page de connexion",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);
    };



    onMounted(() => {
      localStorage.removeItem('storedConst');
      if (store.getters.isUserAuthenticated){
        if (router.currentRoute.value.params.redirect) {
          const stringRec = (router.currentRoute.value.params.redirect) as string;
          router.push({ name: stringRec });
          return false;
        } 
      }

      state.displayLogin = true;

      if (router.currentRoute.value.params.loginpassword) {
        store.dispatch(Actions.LOGOUT);
        const stringRec = (router.currentRoute.value.params.loginpassword) as string;
        state.username = window.atob(stringRec).split("|$|")[0];
        state.password = window.atob(stringRec).split("|$|")[1];
        
        if (submitButton.value) submitButton.value.click();
      }
    });      

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      formLogin
    };
  },
});
